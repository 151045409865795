import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={1}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>

          <p>Work in a team of 2-3 students for this step.</p>
          <p>Think of an advertisement or website you have seen. Think about what that ad or site was trying to get you to do. Maybe they were trying to get you to buy something or to convince you of an idea.</p>
          <p>Can you identify ways that the writers were trying to influence you? What kinds of things do they emphasize in ads? Discuss your ideas with your teammates and write your ideas in your science notebook.</p>
          <p>You will come back to this list later in the lesson.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
